import React from 'react'
import Card from './Card'
import "../postupdate.css";
const PostUpadate = () => {
  return (
    <div className='post_container'>
        <div><Card /></div> 
        <div><Card /></div> 
        <div><Card /></div> 
    </div>
  )
}

export default PostUpadate